<template>
    <!--   todo 机构的展示功能 -->
    <div class="SkuPurchaseDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" label-width="80px" width="auto" size="small">
                    <el-form-item label="名称">
                        <el-input readonly v-model="form.sku.name" />
                    </el-form-item>
                    <el-form-item label="条码">
                        <el-input readonly :value="form.sku.bars.join(' / ')" />
                    </el-form-item>
                    <el-form-item label="规格">
                        <el-input readonly v-model="form.sku.specs" />
                    </el-form-item>
                    <el-form-item label="类目">
                        <el-input readonly v-model="form.sku.category" />
                    </el-form-item>
                    <el-form-item label="单位">
                        <el-input readonly v-model="form.sku.unit" />
                    </el-form-item>
                    <el-form-item label="品牌">
                        <el-input readonly v-model="form.sku.brand" />
                    </el-form-item>
                    <el-form-item label="机构">
                        <el-form-item label="总部" v-if="hasPrivilege('biz.skuPurchase.head.operate')">
                            <el-switch v-model="form.type1" disabled />
                        </el-form-item>
                        <el-form-item label="配送中心" v-if="hasPrivilege('biz.skuPurchase.repo.operate')">
                            <el-switch v-model="form.type2" disabled />
                        </el-form-item>
                        <el-form-item label="门店" v-if="hasPrivilege('biz.skuPurchase.store.operate')">
                            <el-switch v-model="form.type3" disabled />
                        </el-form-item>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'SkuPurchaseDetail',
    props: ['form'],
    data() {
        return {};
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
